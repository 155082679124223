@media screen and (min-width: 1400px) {
  .black-title {
    margin-left: 200px;
    margin-right: 200px;
  }
}

.embed {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.company-img {
  overflow: hidden;
}

.company-img img {
  width: "100%";
  transition: transform 1s ease;
  transform-origin: 50% 50%;
}

.company-img img:hover {
  transform: scale(1.2);
}