.text-editor ul {
  list-style: none;
  padding: 0;
  margin-left: 15px;
  text-align: start;
}

.text-editor li {
  padding-left: 1.3em;
  margin-bottom: 3px;
  font-size: 0.875rem;
}

.text-editor li:before {
  color: rgb(104, 204, 203);
  content: "\f00c";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  /* same as padding-left set on li */
  width: 1.3em;
  /* same as padding-left set on li */
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

/* To show the lines on right 
and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border: 1px solid black;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 1em;
}

.divider:not(:empty)::after {
  margin-left: 1em;
}

.btn-start-over {
  background-color: black!important;
  color: white!important;
}

.btn-navigation {
  background-color: white!important;
}

.btn-navigation:hover {
  background-color: transparent!important;
  border-color: white!important;
  color: white!important;
}

.btn-navigation:hover > div {
  color: white!important;
}