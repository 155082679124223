@tailwind base;
@tailwind components;
@tailwind utilities;

.prose {
  color: inherit;
}

@layer component {
  h1 {
    @apply leading-7 !text-5xl !mb-3;
  }

  h2 {
    @apply leading-7 !text-4xl !mb-3;
  }

  h3 {
    @apply leading-7 !text-3xl !mb-3;
  }

  h4 {
    @apply leading-7 !text-2xl !mb-3;
  }

  h5 {
    @apply leading-7 !text-xl !mb-3;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-sans;
  }

  .bg-primary {
    @apply !text-white;
  }

  .btn {
    @apply !font-normal !text-base;
  }

  .btn-outline.btn-error {
    @apply hover:!text-white;
  }

  .btn-primary {
    @apply !text-white;
  }

  .service-detail > ul {
    @apply !list-disc !list-outside !pl-5;
  }

  .service-detail > ol {
    @apply !list-decimal !list-outside !pl-5;
  }

  .service-detail-departurepass > ul {
    @apply !list-image-[url(assets/images/checkmark/departurepass.png)] !list-outside !pl-5;
  }

  .service-detail-finnsbalidaypass > ul {
    @apply !list-image-[url(assets/images/checkmark/finnsbalidaypass.png)] !list-outside !pl-5;
  }

  .service-detail-splash > ul {
    @apply !list-image-[url(assets/images/checkmark/splash.png)] !list-outside !pl-5;
  }

  .service-detail-superfunpass > ul {
    @apply !list-image-[url(assets/images/checkmark/superfunpass.png)] !list-outside !pl-5;
  }

  .checkmark > ul {
    @apply !list-image-[url(assets/images/checkmark/check.png)] !list-outside !pl-6;
  }
}